import type { PageContext } from 'vike/types'

const paths = [
  '/disclaimers',
  '/terms-of-service',
  '/privacy-policy',
  '/cookie-policy',
  '/content-test',
  /\/bio\/.+/,
  /\/health-conditions\/.+/,
]

export default function route(context: PageContext) {
  const path = context.urlPathname

  if (!paths.some(p => typeof p === 'string' ? p === path : p.test(path))) {
    return false
  }
  
  return {
    routeParams: {
      path: context.urlPathname,
    },
  }
}